<template>
  <div class="help">
    <header class="help__header">
      <h1 class="help__title">Help</h1>
    </header>
    <section id="faqs" class="help__faqs">
      <h2 class="help__section-title">Frequently Asked Questions</h2>
      <ul class="help__faqs-table-of-contents">
        <h3 class="help__faqs-table-of-contents-title">Contents</h3>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question1"
            >Where can I get the app?</a
          >
        </li>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question2"
            >Is the app free?</a
          >
        </li>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question3"
            >What is a Shtum Secret?</a
          >
        </li>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question4"
            >How do I share a Shtum Secret?</a
          >
        </li>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question5"
            >What is a Shtum Key?</a
          >
        </li>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question6"
            >How do I protect a Shtum Secret with a Shtum Key?</a
          >
        </li>
        <li class="help__faqs-table-of-contents-item">
          <a class="help__faqs-table-of-contents-link" href="#question7"
            >How do I read a Shtum Secret that is protected with a Shtum Key?</a
          >
        </li>
      </ul>
      <dl class="help__faqs-questions-with-answers">
        <dt id="question1" class="help__faqs-question">
          Where can I get the app?
        </dt>
        <dd class="help__faqs-answer">
          <p>
            The app is currently available for iOS (<a href=""
              >get it in the App Store</a
            >) and Android (<a class="help__link" href=""
              >get it in Google Play</a
            >). M1 Mac users can also install the app via the Mac App Store.
          </p>
        </dd>
        <dt id="question2" class="help__faqs-question">Is the app free?</dt>
        <dd class="help__faqs-answer">
          <p>
            The app is free to download and use. If you want to enable the Shtum
            Key feature (see
            <a class="help__link" href="#question3">What is a Shtum Key?</a>),
            you can do so through an in-app purchase.
          </p>
        </dd>
        <dt id="question3" class="help__faqs-question">
          What is a Shtum Secret?
        </dt>
        <dd class="help__faqs-answer">
          <p>
            A Shtum Secret is a piece of text that has been converted to a
            string of seemingly random emoji. By storing text as a Shtum Secret
            you can protect it from prying eyes. For example, if you keep a
            diary and you want to make sure some entries stay private, you could
            translate the text to a Shtum Secret.
          </p>
        </dd>
        <dt id="question4" class="help__faqs-question">
          How do I share a Shtum Secret?
        </dt>
        <dd class="help__faqs-answer">
          <p>
            You can share your Shtum Secrets to other apps, either to save them
            (for example, in a notes app) or to send them to other people (for
            example, in a group chat). Because emoji are almost universally
            supported, sharing will work in most apps. The easiest way to share
            to another app is to use the Share option from the main toolbar.
            Alternatively, you can copy your Shtum Secret to your clipboard
            using the button at the top of the screen (when in emoji mode).
            You'll then be able to paste it into another app from your
            clipboard.
          </p>
        </dd>
        <dt id="question5" class="help__faqs-question">What is a Shtum Key?</dt>
        <dd class="help__faqs-answer">
          <p>
            Shtum Keys can be used to protect Shtum Secrets so that other people
            who have the app, but don't know the Shtum Key, can't read them.
            Anyone attempting to translate a Shtum Secret that has been
            protected in this way will be presented with unintelligible
            gobbledegook. If they know the Shtum Key, they can enter it to
            translate the gobbledegook into readable text. So, if you want a
            friend to be able to read one of your Shtum Secrets, make sure they
            know the Shtum Key.
          </p>
          <p>
            Shtum Keys can consist of letters only (no spaces). Memorable words
            that have meaning to you, but are hard for others to guess, make the
            best Shtum Keys.
          </p>
        </dd>
        <dt id="question6" class="help__faqs-question">
          How do I add a Shtum Key?
        </dt>
        <dd class="help__faqs-answer">
          <p>
            To add a Shtum Key you'll need to first activate the feature through
            an in-app purchase. To do this, open settings and select "Purchase
            Shtum Key". Once you have completed the purchase, enable the feature
            in Settings. You will now see a Shtum Key input in the toolbar at
            the bottom of the app window. Type your Shtum Key into this field.
            If you're in emoji mode you'll see the emoji for your Shtum Secret
            all change, showing that it is now protected.
          </p>
        </dd>
        <dt id="question7" class="help__faqs-question">
          How do I read a Shtum Secret that is protected with a Shtum Key?
        </dt>
        <dd class="help__faqs-answer">
          <p>
            If you haven't already done so, you'll first need to activate the
            Shtum Key feature through an in-app purchase. To do this, open
            settings and select "Purchase Shtum Key". Once you have completed
            the purchase, enable the feature in Settings. You will now see a
            Shtum Key input in the toolbar at the bottom of the app window.
            Assuming you know the Shtum Key, enter it in this input field. If
            you're in text mode, the Shtum Secret will now be revealed.
          </p>
        </dd>
      </dl>
    </section>
    <section id="contact" class="help__contact">
      <h2 class="help__section-title">Contact</h2>
      <p class="help__contact-paragraph">
        Please feel free to contact me at
        <a class="help__link" href="mailto:hello@shtum.app">hello@shtum.app</a>
        if the above FAQs have not provided the help you need. I aim to respond
        within 24 hours but, as I'm a part-time independent developer working
        alone, I may not always be able to do so. Thanks for your understanding.
      </p>
      <p class="help__contact-paragraph">
        --<br />Jeremy Burton<br />Developer of Shtum
      </p>
    </section>
  </div>
</template>

<style>
.help {
  margin: 0 0 2rem;
}
.help__header {
  background: var(--brand-primary-50);
}
.help__title {
  max-width: 48rem;
  margin: 0 auto;
  padding: 3rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--base-text-color);
}
.help__faqs,
.help__contact {
  max-width: 48rem;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.help__section-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--base-text-color);
}
.help__link {
  color: var(--brand-secondary-60);
}
.help__faqs-table-of-contents {
  padding: 0;
  list-style: none;
  line-height: 1.5;
}
.help__faqs-table-of-contents-title {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--base-text-color);
}
.help__faqs-table-of-contents-item {
  margin-bottom: 0.5rem;
}
.help__faqs-table-of-contents-link {
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--brand-secondary-60);
}
.help__faqs-questions-with-answers {
  margin: 2rem 0;
}
.help__faqs-question {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--base-text-color);
}
.help__faqs-answer {
  margin: 0 0 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.help__contact-paragraph {
  margin: 0 0 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
</style>
